<template>
  <v-app data-auth>
    <v-card width="442" class="mx-auto pa-12">
      <router-link
        :to="{ name: 'Home' }"
        class="logo grey--text text--darken-4"
      >
        <div class="d-flex flex-column align-center mb-12">
          <v-avatar tile size="64">
            <img :src="logo" id="logo" />
          </v-avatar>
          <div
            style="font-family: 'Cantata One', serif !important"
            class="text-h5 pt-4"
            id="company"
          >
            {{ company }}
          </div>
        </div>
      </router-link>

      <v-container>
        <!-- user message -->
        <v-row no-gutters>
          <v-col>
            <v-alert
              v-if="$store.getters['alert/display']"
              text
              dense
              :type="$store.getters['alert/level']"
              v-text="$store.getters['alert/text']"
              id="message"
            />
          </v-col>
        </v-row>

        <!-- providers -->
        <template v-if="!this.isEmailLink">
          <v-row>
            <v-col>
              <v-btn
                block
                depressed
                outlined
                large
                class="text-none"
                @click="authorizeGoogle"
                id="google"
              >
                <v-icon>mdi-google</v-icon>
                <i18n path="auth.continue with" tag="span" class="pl-4">
                  <template #provider>{{ $t("google") }}</template>
                </i18n>
              </v-btn>
            </v-col>
          </v-row>

          <!-- or -->
          <v-row>
            <v-col>
              <v-divider style="position: relative; top: 50%" />
              <span
                class="px-4 white grey--text text-lighten-1 font-weight-medium"
                style="position: relative; left: calc(50% - 16px)"
              >
                {{ $t("auth.or") }}
              </span>
            </v-col>
          </v-row>
        </template>

        <!-- email -->
        <v-row>
          <v-col>
            <v-text-field
              dense
              outlined
              :label="$t('email')"
              v-model="email.value"
              :error-messages="email.error"
              @blur="validateEmailField"
              @keyup.enter="authorizeEmail"
              id="email"
            />

            <v-btn
              ref="submit"
              block
              depressed
              color="accent"
              class="text-none mb-4"
              @click="authorizeEmail"
              :disabled="loading"
              id="submitEmail"
            >
              {{ $t(labels.submit) }}
            </v-btn>

            <i18n path="auth.notice" tag="div" class="text-body-2 text-center">
              <template #action>{{ $t(labels.action) }}</template>

              <template #terms>
                <a
                  ref="terms"
                  :href="termsOfService"
                  target="_blank"
                  id="terms"
                  v-text="$t('terms.label')"
                />
              </template>
              <template #privacy>
                <a
                  ref="privacy"
                  :href="privacyPolicy"
                  target="_blank"
                  id="privacy"
                  v-text="$t('privacy.label')"
                />
              </template>
            </i18n>
          </v-col>
        </v-row>

        <!-- re-route -->
        <v-row class="mt-12">
          <v-col>
            <v-container>
              <v-row>
                <v-col class="text-center" v-text="$t(labels.question)" />
              </v-row>

              <v-row>
                <v-col class="text-center">
                  <router-link :to="alt" v-text="$t(labels.answer)" />
                </v-col>
              </v-row>
            </v-container>
          </v-col>
        </v-row>
      </v-container>
    </v-card>
  </v-app>
</template>

<script>
import { mapGetters } from "vuex";
import * as validator from "validator";

const EMAIL_FOR_SIGNIN = "user/emailForSignIn";
const SIGNIN_ARGS = ["mode", "lang", "oobCode", "apiKey"];

export default {
  name: "Auth",
  props: {
    labels: {
      type: Object,
      default: () => ({
        action: "auth.sign-in.action",
        question: "auth.sign-in.question",
        answer: "auth.sign-in.answer",
        submit: "auth.sign-in.submit",
      }),
    },
    alt: { type: Object, default: () => ({ name: "SignUp" }) },
  },
  data: () => ({
    loading: false,
    email: {
      value: "",
      error: "",
    },
    isEmailLink: false,
  }),
  computed: {
    ...mapGetters({
      company: "config/company",
      logo: "config/logo",
      termsOfService: "config/termsOfService",
      privacyPolicy: "config/privacyPolicy",
    }),
    logLabel() {
      return this.$route.path.endsWith("/sign-in") ? "login" : "sign_up";
    },
  },
  methods: {
    validateEmailField() {
      const isValidEmail =
        !validator.isEmpty(this.email.value) &&
        validator.isEmail(this.email.value);
      this.email.error = !isValidEmail ? this.$t("auth.invalid email") : "";
      return isValidEmail;
    },
    async authorizeEmail() {
      if (!this.validateEmailField()) {
        return;
      }

      const email = this.email.value;
      this.loading = true;
      try {
        if (this.isEmailLink) {
          await this.$firebase.signInWithEmailLink(email, window.location.href);
          window.localStorage.removeItem(EMAIL_FOR_SIGNIN);
          const query = {};
          Object.entries(this.$route.query)
            .filter(([key]) => !SIGNIN_ARGS.includes(key))
            .forEach(([key, value]) => {
              query[key] = value;
            });
          this.$router.replace({ query }).catch(() => {});
          return;
        }

        this.$firebase.log(this.logLabel, { method: "email" });
        const to = this.$router.resolve({ name: "Home" });
        await this.$firebase.sendSignInLinkToEmail(email, to.href);
        window.localStorage.setItem(EMAIL_FOR_SIGNIN, email);
        this.$store.dispatch(
          "alert/success",
          this.$t("auth.verification email", { email })
        );
        this.email.value = "";
        this.email.error = "";
      } finally {
        this.loading = false;
      }
    },
    async authorizeGoogle() {
      this.loading = true;
      try {
        this.$firebase.log(this.logLabel, { method: "google" });
        await this.$firebase.signInWithGoogleRedirect();
        this.email.value = "";
        this.email.error = "";
      } finally {
        this.loading = false;
      }
    },
  },
  created() {
    if (this.$firebase.isSignInWithEmailLink(window.location.href)) {
      this.$store.dispatch("alert/info", this.$t("auth.confirm email"));
      this.isEmailLink = true;
      return;
    }

    if (this.$route.name === "SignOut") {
      this.$store.dispatch("alert/info", this.$t("auth.sign out"));
      return;
    }
  },
  beforeRouteLeave(to, from, next) {
    this.$store.dispatch("alert/setDisplay");
    return next();
  },
};
</script>
